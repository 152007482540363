import React from 'react'
import { Link } from 'gatsby'

import Layout from 'Layout'

const ErrorPage = (props) => {
  return (
    <Layout>
      <div className='c-error-page'>
        <div className='c-error-page__content'>
          <div className='o-container'>
            <h1 className='u-margin-bottom-none'>404</h1>
            <p>It looks like the page you're looking for doesn't exist</p>
            <Link to='/' className='c-btn'>
              Go to Homepage
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ErrorPage
